import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { CDN } from "./functions";
import { useEffect, useState } from "react";

export default function MypageComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [Active, setActive] = useState([true, false, false]);
  useEffect(() => {
    if (path.includes("membership")) {
      setActive([false, true, false]);
    } else if (path.includes("profile")) {
      setActive([false, false, true]);
    } else {
      setActive([true, false, false]);
    }
  }, [location.pathname]);

  return (
    <Header>
      <MenuContainer>
        <MenuButton
          style={{
            background: Active[0] ? "#2581b3" : "#fff",
            color: Active[0] ? "#fff" : "#2581b3",
          }}
          onClick={() => {
            navigate("/lms/mypage");
          }}>
          내정보
        </MenuButton>
        <MenuButton
          style={{
            background: Active[1] ? "#2581b3" : "#fff",
            color: Active[1] ? "#fff" : "#2581b3",
          }}
          onClick={() => {
            navigate("/lms/membership");
          }}>
          맴버십관리
        </MenuButton>
      </MenuContainer>
      <Close
        onClick={() => {
          navigate("/lms/intro");
        }}>
        <img src={CDN + "/images/intro/assets/close.png"} alt="menu" />
      </Close>
    </Header>
  );
}

const Header = styled.div`
    width: 100%;
    height: calc(0.1625 * var(--app-height));
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  MenuContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin-left: calc(0.0275 * var(--app-height));
    height: 100%;
  `,
  MenuButton = styled.div`
    background: #2581b3;
    color: #fff;
    border-top-left-radius: calc(0.04625 * var(--app-height));
    border-top-right-radius: calc(0.04625 * var(--app-height));
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(0.0925 * var(--app-height));
    padding: calc(0.0375 * var(--app-height));
    font-size: calc(0.02963 * var(--app-height));
    margin-right: calc(0.0125 * var(--app-height));
    font-family: NanumSquareRound;
    font-weight: 800;
    cursor: pointer;
    &:last-of-type {
      background: #fff;
      color: #2581b3;
    }
  `,
  Close = styled.button`
    width: calc(0.11875 * var(--app-height));
    height: calc(0.11875 * var(--app-height));
    margin-right: calc(0.025 * var(--app-height));
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 2;
    display: flex;
    flex-flow: column;
    font-size: calc(0.02375 * var(--app-height));
    font-family: Nanumsquare;
    background: none;
    color: var(--color-text2);
    align-items: center;
    text-transform: uppercase;
  `;
