import { Outlet, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("userIndex") ? true : false;

  useEffect(() => {
    if (isLoggedIn) {
      axios.get("/api/content/membership2").then((res) => {
        if (res.data.status === 3 || 4) {
          return;
        } else {
          alert(res.data.message);
          navigate("/study/content/home", { replace: true });
        }
      });
    }
  }, [isLoggedIn, navigate]);

  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/study/content/home" replace />
  );
};

export default PrivateRoutes;
