import styled from "@emotion/styled";
import axios from "axios";
import { useNavigate } from "react-router";

export default function PayComponent(
  cart: any[],
  setCart: any,
  fetchCart: any[],
  setFetchCart: any
) {
  const navigate = useNavigate();

  const moveToCart = () => {
    const _cart = [...cart];
    setCart(_cart.concat(fetchCart));
    axios
      .post("/api/shop/cart/update", {
        productIds: fetchCart,
      })
      .then((res: any) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setFetchCart([]);
  };

  //임시 카트에 있는 상품을 실제 카트로 옮기는 함수
  const handleCart = () => {
    axios
      .get("/api/auth/login")
      .then((res: any) => {
        if (res.data.id === null) {
          navigate("/login");
        } else {
          moveToCart();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePay = () => {
    axios
      .get("/api/auth/login")
      .then((res: any) => {
        if (res.data.id === null) {
          navigate("/login");
        } else {
          moveToCart();
        }
      })
      .then((res: any) => {
        navigate("/cart");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PayContainer>
      <Container>
        <button onClick={handleCart}>장바구니 추가</button>
      </Container>
      <Container>
        <button onClick={handlePay}>구매하기</button>
      </Container>
    </PayContainer>
  );
}

const PayContainer = styled.div`
    margin: 0 -2px -2px -2px;
    padding-top: 16px;
    display: table;
    table-layout: fixed;
    width: 100%;
    @media (max-width: 768px) {
      padding-top: 8px;
      display: block;
    }
  `,
  Container = styled.div`
    display: table-cell;
    padding: 2px;
    vertical-align: top;
    height: 50px;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
      display: block;
    }
    &:first-of-type {
      border: 1px solid #ffe59d;
      box-sizing: border-box;
      border-radius: 3px;
      background-color: #ffe59d;
    }
    &:nth-of-type(2) {
      border: 1px solid #89ccff;
      box-sizing: border-box;
      border-radius: 3px;
      background-color: #89ccff;
    }
    & button {
      position: relative;
      z-index: 0;
      display: block;
      background: rgba(0, 0, 0, 0);
      margin: auto;
      font-size: 18px;
      line-height: 54px;
      font-weight: bold;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  `;
