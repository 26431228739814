import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import axios from "axios";
import MenuComponent from "./menu";
import { useEffect, useState } from "react";

export default function NavbarComponent(props: any) {
  const component = (
    <header>
      <TopMenuContainer>
        <ul>
          {/* <TopMenuComponent>
            <TopMenuOutLink href="https://a.vandi.biz/">화상영어</TopMenuOutLink>
          </TopMenuComponent> */}
          <TopMenuComponent>
            <TopMenuInnerLink to="/teacher">스터디관리</TopMenuInnerLink>
          </TopMenuComponent>
          <TopMenuComponent>
            <TopMenuOutLink href="https://cafe.naver.com/ieafterstudy">네이버카페</TopMenuOutLink>
          </TopMenuComponent>
        </ul>
        <AuthComponent />
      </TopMenuContainer>
      <MenuComponent />
    </header>
  );

  return component;
}

const AuthComponent: React.FC = () => {
  const [isLoggedIn, setisLoggedIn] = useState(false);

  useEffect(() => {
    axios
      .get("/api/auth/login")
      .then((res) => {
        const info = res.data;
        setisLoggedIn(info.state);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return isLoggedIn ? (
    <div>
      <TopsignMenu to="/lms/mypage">마이페이지</TopsignMenu>
      <TopLogout
        onClick={() => {
          axios
            .post("/api/auth/out")
            .then((res) => {
              localStorage.removeItem("userIndex");
              localStorage.removeItem("userName");
              localStorage.removeItem("academyIndex");
              window.location.href = "/";
            })
            .catch((err) => {
              console.log(err);
            });
        }}>
        로그아웃
      </TopLogout>
    </div>
  ) : (
    <TopsignMenu to="/login">로그인</TopsignMenu>
  );
};

const TopMenuContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    background: #eeeeee;
    padding: 0 10%;
    z-index: 999;
    @media (max-width: 768px) {
      display: none;
    }
  `,
  TopMenuComponent = styled.li`
    display: inline-block;
    vertical-align: middle;
  `,
  TopMenuOutLink = styled.a`
    font-size: 15px;
    padding-right: 25px;
    color: #000;
  `,
  TopsignMenu = styled(Link)`
    font-size: 17px;
    padding-left: 25px;
    margin-right: 25px;
    color: #000;
    font-weight: 600;
  `,
  TopLogout = styled.button`
    font-size: 17px;
    height: 100%;
    font-weight: 600;
  `,
  TopMenuInnerLink = styled(Link)`
    font-size: 15px;
    padding-right: 25px;
    color: #000;
  `;
