import { Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const isLoggedIn = localStorage.getItem("userIndex") ? true : false;

  useEffect(() => {
    // 로그인 되어있으면 로그인인증 안함
    if (isLoggedIn) return;

    axios.get("/api/auth/login").then((res) => {
      if (res.data) {
      } else {
        localStorage.removeItem("userIndex");
        localStorage.removeItem("userName");
        localStorage.removeItem("academyIndex");
        <Navigate to="/" replace />;
      }
    });
  }, []);

  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoutes;
